import * as React from 'react';
import Helmet from "react-helmet";
import { Polygon } from "@fitplan/lib/components/Chevron/Polygon";
import { css } from "@emotion/core";
import { graphql } from "gatsby";

import PageWrapper from "../components/pageWrapper/PageWrapper";
import {Subscribe} from "../components/subscribe";
import localize from "../hoc/Localize";
import Grid from "../components/grid/grid";
import "../components/trainers/trainers.scss";

export interface Props {
    className: string;
    data: {
        sanityTrainer: {
            trainerType: {
                title: string;
                description: string;
                keywords: string[];
            };
        };
        allFitplanAthletes: {};
    };
}

const TrainersPage: React.SFC<Props> = props => {
    const { allFitplanAthletes } = props.data;
    const metaData = props.data.sanityTrainer.trainerType;

    return (
        <PageWrapper {...props}>
            <Helmet
                title={metaData.title}
                meta={[
                    { name: "description", content: metaData.description },
                    { name: "keywords", content: metaData.keywords.join(", ") },
                ]}
            />
            <div className="trainers">
                <Grid component="trainers" cards={allFitplanAthletes} />
                <Polygon polygonColor="#1b1c1c" />
                <Subscribe />
            </div>
            <div css={css`
                background-color: #212121;
            `}>
                <Polygon polygonColor="#111213" borderColor="rgb(0, 255, 179)"  />
            </div>
        </PageWrapper>
    );
};

export const query = graphql`
    query athletesQuery {
        allFitplanAthletes {
            edges {
                node {
                    id
                    slug
                    firstName
                    lastName
                    sex
                    url
                    link
                    athleteShortDescription
                    athleteImage
                }
            }
        }
        sanityTrainer {
            trainerType {
                title {
                    _type
                    en
                    es
                }
                description {
                    _type
                    en
                    es
                }
                keywords {
                    _type
                    en
                    es
                }
            }
        }
    }
`;

export default localize(TrainersPage);
